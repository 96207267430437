import React from "react";

function InputShow({ type, value }) {
  return (
    <div className="flex mt-6 max-w-[500px] w-full flex-col items-center justify-start">
      <div className="flex gap-1 items-start w-full justify-start">
        <p className="font-medium text-white">{type}</p>
      </div>

      <input
      disabled={true}
        className="w-full mt-2 shadow-lg h-10 rounded-md border bg-zinc-800 border-zinc-400 p-2 focus:border-zinc-200 text-gray-300"
        value={value}
      />
    </div>
  );
}

export default InputShow;
