import React from "react";
import Profile from "./Profile";
import Setup from "./Setup";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import StartScreen from "./Setup/StartScreen";
import MovingVectors from "../components/MovingVectors";

function Loading() {
  const { id } = useParams();
  const [loading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState();
  const [userData, setUserData] = useState();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://api.troylabs.vc/user/${id}`);
        return response.data;
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.troylabs.vc/check/${id}`);
        setIsActive(response.data.active);
        if (response.data.active) {
          let userData = await fetchUserData();
          setUserData(userData);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError(error);
        setIsLoading(false);
      }
    };

    if (id) fetchData();
  }, [id]);

  while (loading) {
    return (
      <div className="w-screen h-screen overflow-hidden relative max-h-[100vh] max-w-[100vw]">
        <StartScreen />
        <MovingVectors />
      </div>
    );
  }

  const isMobile = window.innerWidth < 768;

  if (!isMobile) {
    return (
      <div className="w-screen h-screen overflow-hidden relative max-h-[100vh] max-w-[100vw]">
        <div className="text-white text-3xl w-full h-full items-center justify-center relative z-50 flex text-center flex-col gap-4">
          <b className="underline underline-offset-8">Something went wrong</b>
          <p className="text-xl font-light">
            Sorry for the inconvenience, Touchbase is only setup for use on
            mobile devices.
          </p>
        </div>
        <MovingVectors />
      </div>
    );
  }

  if (error) {

    return (
      <div className="w-screen h-screen overflow-hidden relative max-h-[100vh] max-w-[100vw]">
        <div className="text-white text-3xl w-full h-full items-center justify-center relative z-50 flex text-center flex-col gap-4">
          <b className="underline underline-offset-8">Something went wrong</b>
          <p className="text-xl font-light">{error.response.data.message}</p>
        </div>
        <MovingVectors />
      </div>
    );
  }

  if (isActive) {
    return <Profile id={id} userData={userData} />;
  } else if (!isActive) {
    return <Setup id={id} />;
  }
}

export default Loading;
