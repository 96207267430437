import React from "react";

function Input({ title, required, value, setValue, type, subtitle, name }) {
  const inputType = type === "pin" ? "password" : type;
  const pattern = type === "pin" ? "[0-9]{4}" : null;
  const maxLength = type === "pin" ? 4 : undefined;

  return (
    <div className="flex mt-6 max-w-[500px] w-full flex-col items-center justify-start">
      <div className="flex gap-1 items-start w-full justify-start">
        <p className="font-medium text-white">{title}</p>
        {required && <p className=" font-bold text-blue-500">*</p>}
      </div>

      {subtitle && (
        <div className="w-full flex items-center gap-2 mt-1 mb-1">
          <div className="rounded-sm w-[2px]  bg-blue-500 h-full" />
          <p className="text-xs font-light text-zinc-300">{subtitle}</p>
        </div>
      )}

      <input
        type={inputType}
        value={value}
        pattern={pattern}
        maxLength={maxLength}
        onChange={(e) => {
          if (
            type !== "pin" ||
            (type === "pin" && /^[0-9]*$/.test(e.target.value))
          ) {
            setValue(e.target.value);
          }
        }}
        name={name || title}
        className="w-full mt-2 shadow-lg h-10 rounded-md border bg-zinc-800 border-zinc-400 p-2 focus:border-zinc-200 text-white"
      />
    </div>
  );
}

export default Input;
