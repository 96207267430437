import React from "react";
import Input from "../../components/Input";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Touchbase from "../../assets/Touchbase.svg";
import FileInput from "../../components/FileInput";

function Images({ prevStep, nextStep, formData, setFormData }) {




  const handleFileChange = (imageKey, fileData) => {

    if (!fileData || !fileData.file) {
      console.error("No file provided");
      return;
    }

    setFormData((currentFormData) => ({
      ...currentFormData,
      images: {
        ...currentFormData.images,
        [imageKey]: fileData,
      },
    }));
  };

  const isFormValid = () => {
    // Check if all images are provided
    if (
      formData.profileImage.file &&
      formData.profileImage.file != null
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="w-screen overflow-hidden relative h-screen z-[150] pt-0 pb-0 flex flex-col items-center justify-start">
      <div
        className="w-[100vw] border-b-2 border-[#141414]  min-h-36 h-36 bg-black shadow-inner"
        style={{
          backgroundImage: `url('${formData.backgroundImage?.previewUrl}')`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      />

      <div className="w-full h-auto pl-6 pr-6">
        <div className="w-full flex items-baseline justify-between h-36 mt-[-22%]">
          <div
            className="w-36 border-4 border-[#141414] h-36 rounded-full shadow-md relative bg-white"
            style={{
              backgroundImage: `url('${formData?.profileImage?.previewUrl}')`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <div
              className="min-w-14 min-h-14 absolute border-2 border-[#141414] bg-white shadow-sm rounded-full bottom-4 right-[-20%]"
              style={{
                backgroundImage: `url('${formData?.companyImage?.previewUrl}')`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full mt-8 flex flex-col p-8 pt-o pb-0 items-center justify-center">
        <h2 className="text-3xl w-full text-center mb-4 font-bold text-white">
          Profile Images
        </h2>

        <FileInput
          id="profileImageInput"
          title="Profile Image"
          required
          setValue={(fileData) => handleFileChange("profileImage", fileData)}
        />
        <FileInput
          id="companyImageInput"
          title="Company Logo"
          setValue={(fileData) => handleFileChange("companyImage", fileData)}
        />
        <FileInput
          id="backgroundImageInput"
          title="Background Image"
          setValue={(fileData) => handleFileChange("backgroundImage", fileData)}
        />

        <div className="w-full gap-2 flex items-center justify-center mt-8">
          <button
            onClick={prevStep}
            className="cursor-pointer text-zinc-300 bg-zinc-700 hover:bg-zinc-600 border border-zinc-600 rounded-lg p-2 px-4 duration-200 hover:scale-105 active:scale-95 transition"
          >
            Previous
          </button>
          <button
            onClick={isFormValid() ? nextStep : undefined}
            disabled={!isFormValid()}
            className={`${
              isFormValid()
                ? "cursor-pointer opacity-100 text-white"
                : "cursor-not-allowed opacity-75 text-zinc-500"
            } ${
              isFormValid() ? "bg-blue-600 hover:bg-blue-500" : "bg-zinc-800"
            } border ${
              isFormValid() ? "border-blue-800" : "border-zinc-900"
            } rounded-lg p-2 px-6 duration-200 ${
              isFormValid() ? "hover:scale-105 active:scale-95 shadow-lg" : ""
            } transition`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Images;
