import React from 'react'
import StartScreen from './Setup/StartScreen'
import MovingVectors from '../components/MovingVectors'

function Landing() {
  return (
    <div className="w-screen h-screen overflow-hidden relative max-h-[100vh] max-w-[100vw]">
        <StartScreen />
        <MovingVectors />
      </div>
  )
}

export default Landing