import LinkedIn from "@mui/icons-material/LinkedIn";
import React from "react";
import LinkIcon from "@mui/icons-material/Link";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MessageIcon from "@mui/icons-material/Message";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import BookIcon from "@mui/icons-material/Book";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import MapIcon from "@mui/icons-material/Map";
import axios from "axios";

const IconMap = {
  custom: LinkIcon,
  scheduler: CalendarMonthIcon,
  phone: MessageIcon,
  facebook: FacebookIcon,
  youtube: YouTubeIcon,
  website: WebAssetIcon,
  blog: BookIcon,
  music: LibraryMusicIcon,
  location: MapIcon,
};

function Link({ title, id, url, editing, text, icon, setLinks, links }) {
  let Icon = IconMap[title];

  function formatPhoneNumber(inputNumber) {
    // Remove everything except digits and plus signs
    const cleaned = ("" + inputNumber).replace(/[^\d+]/g, "");
    return cleaned;
  }

  const openLink = () => {
    let finalUrl = url; // Start with the original URL

    // Handling phone links
    if (title === "phone") {
      const phoneNumber = formatPhoneNumber(finalUrl); // Format the phone number
      window.location.href = `sms:${phoneNumber}`;
      return;
    }

    // Handling location links
    else if (title === "location") {
      finalUrl = `https://www.google.com/maps/place/${encodeURIComponent(
        text
      )}`;
      window.open(finalUrl, "_blank", "noopener, noreferrer");
      return;
    } else {
      // Check if the URL starts with http://, https://, or ftp://
      if (!url.match(/^[a-zA-Z]+:\/\//)) {
        finalUrl = "https://" + url;
      }
      window.open(finalUrl, "_blank", "noopener, noreferrer");
      return;
    }
  };

  const deleteLink = () => {
    if (!window.confirm("Are you sure you want to delete this link?")) {
      return;
    }

    try {
      const response = axios.delete(`https://api.troylabs.vc/links/${id}`);
      setLinks(links.filter((link) => link.id !== id));
    } catch (error) {
      console.error("Failed to delete link", error);
      alert("Failed to delete link, please try again.");
    }
  };

  return (
    <div className="relative overflow-visible w-24 h-28 flex flex-col items-center justify-center gap-2">
      <div
        onClick={openLink}
        className="min-w-20 min-h-20 flex items-center justify-center rounded-md shadow-lg p-4 bg-[#194367]"
      >
        <Icon className="text-white h-full w-full min-w-12 min-h-12" />
      </div>
      <p className="text-gray-400 line-clamp-1 text-center">{text === "" ? title : text}</p>
      {editing && (
        <button
          onClick={deleteLink}
          className="bg-red-500 w-6 h-6 absolute rounded-md text-white top-[-5px] right-0"
        >
          X
        </button>
      )}
    </div>
  );
}

export default Link;
