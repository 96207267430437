import React, { useState } from "react";
import Input from "./Input";
import CloseIcon from "@mui/icons-material/Close";

function PinEntry({ setShowModal, userPin, setEditAccess }) {
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");

  return (
    <div className="w-screen h-screen bg-black/90 absolute flex items-center justify-center">
    <div className="p-8 z-20 py-16 rounded-md border-2 border-[#333333] drop-shadow-xl bg-[#121212]">
      <h1 className="text-white text-2xl font-semibold w-full text-center">
        Pin Entry
      </h1>
      <h3 className="text-gray-300 mt-4 text-md font-medium text-center">
        Enter your pin to edit your account.
      </h3>
      <Input
        title="Pin"
        required
        type={"pin"}
        value={pin}
        placeholder="Your pin"
        setValue={(newValue) => setPin(newValue)}
      />

      <button onClick={() => setShowModal(false)} className="absolute top-2 right-2 text-white">
        <CloseIcon />
      </button>

      {error && <p className="text-red-500 mt-4 text-center underline underline-offset-4">{error}</p>}

      <div className="w-full flex flex-col items-center justify-center mt-8">
        <button
          onClick={() => {
            if (pin.length < 4) {
              setError("Pin must be 4 digits");
              return;
            }

            setError("");

            if (pin === userPin) {
              setEditAccess(true);
              setShowModal(false);
            } else {
              setError("Incorrect pin.");
            }
          }}
          disabled={!pin}
          className={`${
            pin.length < 4 ? "bg-gray-500" : "bg-blue-500"
          } w-full h-10 rounded-md bg-blue-500 text-white`}
        >
          Submit
        </button>
      </div>
    </div>
    </div>
  );
}

export default PinEntry;
