import React from "react";
import axios from "axios";
import InputShow from "./InputShow";

function Final({ prevStep, formData, id }) {
  const isFormValid = () => {
    return "Cameron" === "Cameron";
  };

  const [loading, setLoading] = React.useState(false);

  const createAccount = async () => {
    const formPayload = new FormData();

    setLoading(true)

    // Append personal, professional, and social data
    formPayload.append("name", formData.personal.name);
    formPayload.append("email", formData.personal.email);
    formPayload.append("pin", formData.personal.pin);
    formPayload.append("role", formData.professional.role);
    formPayload.append("company", formData.professional.company);
    formPayload.append("university", formData.professional.university);
    formPayload.append("linkedin", formData.social.linkedin);
    formPayload.append("github", formData.social.github);
    formPayload.append("x", formData.social.x);
    // Append Instagram if it's part of your form
    // formPayload.append('instagram', formData.social.instagram);

    // Append files
    if (formData.images.profileImage.file) {
      formPayload.append("profileImage", formData.images.profileImage.file);
    }
    if (formData.images.companyImage.file) {
      formPayload.append("companyImage", formData.images.companyImage.file);
    }
    if (formData.images.backgroundImage.file) {
      formPayload.append(
        "backgroundImage",
        formData.images.backgroundImage.file
      );
    }

    try {
      const response = await axios.post(
        `https://api.troylabs.vc/create/${id}`,
        formPayload, 
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false)
      window.location.reload();
    } catch (error) {
      setLoading(false)
      console.error("Error sending data to backend:", error.response);
      alert(`Error creating account, please try again: ${error.response.message}`, error.response.message);
    }
  };

  return (
    <div className="w-screen overflow-hidden relative h-screen z-[150] p-8 pt-0 pb-0 flex flex-col items-center justify-center">
      <h2 className="text-3xl w-full text-center font-bold text-white">
        Account Overview
      </h2>

      <div className="w-full gap-2 flex items-center justify-center mt-4">
        <InputShow type={"Name"} value={formData.personal.name} />
        <InputShow type={"Email"} value={formData.personal.email} />
      </div>
      <div className="w-full gap-2 flex items-center justify-center">
        {formData.professional?.role && (
          <InputShow type={"Role"} value={formData.professional?.role} />
        )}
        {formData.professional?.company && (
          <InputShow type={"Company"} value={formData.professional?.company} />
        )}
      </div>
      {formData.professional?.university && (
        <InputShow
          type={"University"}
          value={formData.professional?.university}
        />
      )}
      {formData.social?.linkedin && (
        <InputShow type={"LinkedIn"} value={formData.social?.linkedin} />
      )}
      <div className="w-full gap-2 flex items-center justify-center">
        {formData.social?.github && (
          <InputShow type={"Github"} value={formData.social?.github} />
        )}
        {formData.social?.x && (
          <InputShow type={"X / Twitter"} value={formData.social?.x} />
        )}
      </div>

      <div className="w-full gap-4 flex flex-col-reverse items-center justify-center mt-8">
        <button
          onClick={prevStep}
          disabled={loading}
          className="cursor-pointer w-full text-zinc-300 bg-zinc-700 hover:bg-zinc-600 border border-zinc-600 rounded-lg p-2 px-4 duration-200 hover:scale-105 active:scale-95 transition"
        >
          Previous
        </button>
        <button
          onClick={() => createAccount()}
          disabled={loading}
          className={`${
            isFormValid()
              ? "cursor-pointer opacity-100 text-white"
              : "cursor-not-allowed opacity-75 text-zinc-500"
          } ${
            !loading ? "bg-blue-600 hover:bg-blue-500" : "bg-zinc-800"
          } border ${
            !loading ? "border-blue-800" : "border-zinc-900"
          } rounded-lg p-2 px-6 duration-200 ${
            !loading ? "hover:scale-105 active:scale-95 shadow-lg" : ""
          } transition w-full`}
        >
          Create Account
        </button>
      </div>
    </div>
  );
}

export default Final;
