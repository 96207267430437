import React from 'react'
import TroyLabs from '../assets/TroyLabs.png'

function Spinny() {
  return (
    <div className=''>
        <img  src={TroyLabs} alt="TroyLabs" />
    </div>
  )
}

export default Spinny