import React from "react";
import Input from "../../components/Input";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Touchbase from "../../assets/Touchbase.svg";

function Personal({ nextStep, formData, handleFormData }) {
  const handleChange = (key, value) => {
    handleFormData("personal", key, value);
    
  };

  const isFormValid = () => {
    return (
      (formData.name.trim() && formData.name.trim().length > 0) &&
      (formData.email.trim() && formData.email.trim().length > 0 && /\S+@\S+\.\S+/.test(formData.email)) &&
      formData.pin.trim().length === 4
    );
  };

  return (
    <div className="w-screen overflow-hidden relative h-screen z-[150] p-8 pt-0 pb-0 flex flex-col items-center justify-center">
      <h2 className="text-3xl w-full text-center font-bold text-white">
        Personal Info
      </h2>
      <h6 className="text-md w-full text-center font-light text-zinc-300 mb-8 mt-1">
        The basics
      </h6>
      <Input
        title="Name"
        required
        subtitle={"Full name so people can recognize you"}
        type={"text"}
        value={formData.name}
        placeholder="Your name"
        setValue={(value) => handleChange("name", value)}
      />
      <Input
        title="Email"
        required
        subtitle={"Don't worry, we won't spam you"}
        type={"email"}
        value={formData.email}
        placeholder="Your email"
        setValue={(value) => handleChange("email", value)}
      />
      <Input
        title="Pin"
        subtitle={"4-digit code to edit your account later on"}
        required
        type={"pin"}
        value={formData.pin}
        placeholder="Your pin"
        setValue={(value) => handleChange("pin", value)}
      />
      <div className="w-full gap-2 flex items-center justify-center mt-24">
        <button
          onClick={isFormValid() ? nextStep : undefined}
          disabled={!isFormValid()} 
          className={`${isFormValid() ? "cursor-pointer opacity-100 text-white" : "cursor-not-allowed opacity-75 text-zinc-500"} ${
            isFormValid() ? "bg-blue-600 hover:bg-blue-500" : "bg-zinc-800"
          } border ${
            isFormValid() ? "border-blue-800" : "border-zinc-900"
          } rounded-lg p-2 px-6 duration-200 ${
            isFormValid() ? "hover:scale-105 active:scale-95 shadow-lg" : ""
          } transition`}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Personal;
