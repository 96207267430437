import React, { useEffect, useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import Link from "../components/Link";
import PinEntry from "../components/PinEntry";
import AddLinks from "../components/AddLinks";
import Connect from "../components/Connect";
import AddIcon from '@mui/icons-material/Add';

import { useWindowSize } from "../hooks/useWindowSize";
import { useSwipeable } from "react-swipeable";


import { ReactComponent as BottomBlur } from "../assets/BottomBlur.svg";
import { ReactComponent as TopBlur } from "../assets/TopBlur.svg";

function Profile({ id, userData }) {
  const [width, height] = useWindowSize();
  const [showModal, setShowModal] = useState(false);
  const [connectModal, setConnectModal] = useState(false);
  const [linksModal, setLinksModal] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [links, setLinks] = useState([]);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);

  const nextChunk = () => {
    setCurrentChunkIndex((prevIndex) =>
      prevIndex + 1 < chunkedLinks.length ? prevIndex + 1 : prevIndex
    );
  };

  const prevChunk = () => {
    setCurrentChunkIndex((prevIndex) =>
      prevIndex - 1 >= 0 ? prevIndex - 1 : 0
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextChunk(),
    onSwipedRight: () => prevChunk(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const chunkLinks = (linksArray, chunkSize) => {
    const result = [];
    for (let i = 0; i < linksArray.length; i += chunkSize) {
      result.push(linksArray.slice(i, i + chunkSize));
    }
    return result;
  };

  const chunkedLinks = chunkLinks(links, 3);
  const isSmallScreen = height < 750;

  useEffect(() => {
    if (userData) {
      setLinks(userData.links);
    }
  }, [userData]);

  return (
    <div className="w-screen relative h-screen overflow-hidden bg-[#141414] flex flex-col items-center">
      <div
        className="w-full min-h-48 bg-black shadow-inner"
        style={{
          backgroundImage: `url('${userData?.backdrop_picture}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <div className="w-full h-auto pl-6 pr-6 border-t-2 border-[#333333]">
        <div className="w-full flex items-baseline justify-between h-36 mt-[-22%]">
          <div
            className="w-36 border-2 border-[#333333] h-36 rounded-full shadow-md relative bg-white"
            style={{
              backgroundImage: `url('${userData?.profile_picture}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              className="min-w-14 min-h-14 absolute border-2 border-[#333333] bg-white shadow-sm rounded-full bottom-4 right-[-20%]"
              style={{
                backgroundImage: `url('${userData?.company_logo}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </div>
          {!editAccess && (
            <button
              onClick={() => setShowModal(true)}
              className="text-white p-2 rounded-md font-bold text-md hover:bg-[#121212]"
            >
              <ModeEditIcon />
            </button>
          )}
        </div>
        <div className="w-full mt-4">
          <h1 className="text-xl font-bold text-white">{userData?.name}</h1>
          <p className="text-sm mt-2 text-gray-200">
            {userData?.role} {userData?.company && "at"} {userData?.company}
          </p>
          <p className="text-sm mt-1 text-gray-400">{userData?.university}</p>
        </div>
        <div className="w-full flex gap-4 mt-4">
          {userData?.linkedin && userData?.linkedin.length > 1 && (
            <button
              onClick={() => window.open(userData?.linkedin, "_blank")}
              className="text-white w-8 h-8 hover:bg-[#121212] rounded-md"
            >
              <LinkedInIcon />
            </button>
          )}
          {userData?.github && userData?.github.length > 1 && (
            <button
              onClick={() => window.open(userData?.github, "_blank")}
              className="text-white w-8 h-8 hover:bg-[#121212] rounded-md"
            >
              <GitHubIcon />
            </button>
          )}
          {userData?.x && userData?.x.length > 1 && (
            <button
              onClick={() => window.open(userData?.x, "_blank")}
              className="text-white w-8 h-8 hover:bg-[#121212] rounded-md"
            >
              <XIcon />
            </button>
          )}
        </div>
      </div>

      <div className="w-full pt-4 h-[100%] pl-6 pr-6 flex flex-col items-center">
        {editAccess ? (
          <div className="w-full flex gap-4 items-center">
            <button
              className="text-gray-200 w-3/5 font-normal rounded-md bg-blue-500  p-2 text-md mt-4 shadow-md hover:bg-blue-600"
              onClick={() => setLinksModal(true)}
            >
              Add Links
            </button>
            <button
              className="text-gray-200 w-2/5 font-normal rounded-md bg-red-500 p-2 text-md mt-4 shadow-md hover:bg-red-600"
              onClick={() => setEditAccess(false)}
            >
              Stop Editing
            </button>
          </div>
        ) : (
          <button
            className="text-gray-200 font-normal rounded-md bg-blue-500 w-full p-2 text-md mt-4 shadow-md hover:bg-blue-600"
            onClick={() => setConnectModal(true)}
          >
            Connect With Me <AddIcon/>
          </button>
        )}

        <div className="w-full h-full flex justify-center mt-8" {...handlers}>
          {isSmallScreen && links.length > 3 ? (
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-row w-full justify-center gap-4">
                {chunkedLinks[currentChunkIndex].map((link) => (
                  <Link
                    key={link.id}
                    id={link.id}
                    title={link.type}
                    url={link.url}
                    editing={editAccess}
                    links={links}
                    setLinks={setLinks}
                    text={link.text}
                  />
                ))}
              </div>
              <div className="flex mt-4">
                {chunkedLinks.map((_, index) => {
                  const isActive = index === currentChunkIndex;
                  const dotClass = isActive ? "bg-blue-800" : "bg-gray-400";
                  return (
                    <button
                      key={index}
                      onClick={() => setCurrentChunkIndex(index)}
                      className={`mx-2 h-2 w-2 rounded-full cursor-pointer ${dotClass}`}
                      aria-label={`Go to section ${index + 1}`}
                    ></button>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-3 justify-items-center gap-8 place-content-center">
              {/* Render all links if not a small screen or if there are 3 or fewer links */}
              {links.map((link) => (
                <Link
                  key={link.id}
                  id={link.id}
                  title={link.type}
                  url={link.url}
                  editing={editAccess}
                  links={links}
                  setLinks={setLinks}
                  text={link.text}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <PinEntry
          setShowModal={setShowModal}
          userPin={userData?.pin}
          setEditAccess={setEditAccess}
        />
      )}

      {linksModal && (
        <AddLinks
          setShowModal={setLinksModal}
          userId={id}
          links={links}
          setLinks={setLinks}
        />
      )}

      {connectModal && (
        <Connect
          setConnectModal={setConnectModal}
          userName={userData?.name}
          userEmail={userData?.email}
        />
      )}

      <TopBlur className="absolute w-full pointer-events-none opacity-70 right-0 top-0" />
      <BottomBlur className="absolute w-full pointer-events-none opacity-60 bottom-0" />
    </div>
  );
}

export default Profile;

