import React, { useState } from "react";
import Input from "./Input";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MessageIcon from "@mui/icons-material/Message";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import BookIcon from "@mui/icons-material/Book";
import MapIcon from "@mui/icons-material/Map";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import axios from "axios";

const IconMap = {
  custom: LinkIcon,
  scheduler: CalendarMonthIcon,
  phone: MessageIcon,
  facebook: FacebookIcon,
  youtube: YouTubeIcon,
  website: WebAssetIcon,
  blog: BookIcon,
  location: MapIcon,
};

function AddLinks({ setShowModal, links, setLinks, userId }) {
  const [selectedLink, setSelectedLink] = useState("");
  const [linkValue, setLinkValue] = useState("");
  const [textValue, setTextValue] = useState("");
  const [loading, setLoading] = useState(false);

  const addLink = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.troylabs.vc/addLink",
        {
          user_id: userId,
          url: linkValue,
          type: selectedLink,
          text: textValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      if (data.error) {
        console.error(data.error);
      } else {
        setLinks([...links, data]);
        setSelectedLink("");
        setLinkValue("");
        setTextValue("");
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to add link", error);
      setLoading(false);
      alert("Failed to add link, please try again.");
    }
  };

  const LinkTypes = {
    custom: "Custom URL",
    scheduler: "Scheduler Link",
    phone: "Phone Number",
    facebook: "Facebook URL",
    youtube: "YouTube URL",
    website: "Website URL",
    blog: "Blog URL",
    location: "Location Address",
  };

  let disabled = links.length == 6 ? true : false;

  let SelectedIcon = IconMap[selectedLink];

  return (
    <div className=" w-screen h-screen bg-black/90 absolute flex items-center justify-center">
      <div className="z-20 p-10 py-16 rounded-md border-2 border-[#333333] drop-shadow-xl  bg-[#121212] transition-all duration-500">
        <button
          onClick={() => setShowModal(false)}
          className="absolute top-2 right-2 text-white"
        >
          <CloseIcon />
        </button>

        {selectedLink === "" ? (
          <div className="animate-slideIn">
            <h1 className="text-white text-2xl font-semibold w-full text-center">
              Links
            </h1>
            <h3 className="text-gray-300 mt-4 text-md font-medium text-center">
              Choose links to add to your profile.
            </h3>
            <h3 className="text-blue-500 underline underline-offset-8 mt-4 text-md font-medium text-center">
              {links.length} / 6 links active
            </h3>

            {/* Button grid */}
            <div className="w-full flex flex-col items-center justify-center mt-4">
              <div className="w-full grid grid-cols-2 gap-4 mt-8">
                <button
                  disabled={disabled}
                  onClick={() => setSelectedLink("custom")}
                  className="flex items-center justify-center border-2 border-gray-500 rounded-md p-2 hover:bg-gray-600 hover:text-[#121212]"
                >
                  <LinkIcon className="text-white" />
                  <p className="text-white ml-2">custom</p>
                </button>
                <button
                  disabled={disabled}
                  onClick={() => setSelectedLink("scheduler")}
                  className="flex items-center justify-center border-2  border-gray-500 rounded-md p-2 hover:bg-gray-600 hover:text-[#121212]"
                >
                  <CalendarMonthIcon className="text-white" />
                  <p className="text-white ml-2">scheduler</p>
                </button>
                <button
                  disabled={disabled}
                  onClick={() => setSelectedLink("phone")}
                  className="flex items-center justify-center border-2  border-gray-500 rounded-md p-2 hover:bg-gray-600 hover:text-[#121212]"
                >
                  <MessageIcon className="text-white" />
                  <p className="text-white ml-2">phone</p>
                </button>
                <button
                  disabled={disabled}
                  onClick={() => setSelectedLink("facebook")}
                  className="flex items-center justify-center border-2  border-gray-500 rounded-md p-2 hover:bg-gray-600 hover:text-[#121212]"
                >
                  <FacebookIcon className="text-white" />
                  <p className="text-white ml-2">facebook</p>
                </button>
                <button
                  disabled={disabled}
                  onClick={() => setSelectedLink("youtube")}
                  className="flex items-center justify-center border-2  border-gray-500 rounded-md p-2 hover:bg-gray-600 hover:text-[#121212]"
                >
                  <YouTubeIcon className="text-white" />
                  <p className="text-white ml-2">youtube</p>
                </button>
                <button
                  disabled={disabled}
                  onClick={() => setSelectedLink("website")}
                  className="flex items-center justify-center border-2  border-gray-500 rounded-md p-2 hover:bg-gray-600 hover:text-[#121212]"
                >
                  <WebAssetIcon className="text-white" />
                  <p className="text-white ml-2">website</p>
                </button>
                <button
                  disabled={disabled}
                  onClick={() => setSelectedLink("blog")}
                  className="flex items-center justify-center border-2  border-gray-500 rounded-md p-2 hover:bg-gray-600 hover:text-[#121212]"
                >
                  <BookIcon className="text-white" />
                  <p className="text-white ml-2">blog</p>
                </button>

                <button
                  disabled={disabled}
                  onClick={() => setSelectedLink("location")}
                  className={`flex items-center justify-center border-2  border-gray-500 rounded-md p-2 hover:bg-gray-600 hover:text-[#121212]`}
                >
                  <MapIcon className="text-white" />
                  <p className="text-white ml-2">location</p>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="inset-0 animate-slideOver w-full px-8">
            <button
              onClick={() => setSelectedLink("")}
              className="text-white absolute top-2 left-2"
            >
              <ArrowBackIosNewIcon />
            </button>
            <div className="w-full flex items-center justify-center">
              <SelectedIcon className="min-h-20 min-w-20 w-full  text-white" />
            </div>

            <h1 className="text-white text-xl font-semibold w-full text-center mt-4">
              {LinkTypes[selectedLink]}
            </h1>
            <div className="mt-8 w-full">
              <Input
                title={`${LinkTypes[selectedLink]}`}
                value={linkValue}
                setValue={setLinkValue}
                placeholder={`Enter ${LinkTypes[selectedLink]}`}
                required
              />
              <Input
                title={`Link Text`}
                value={textValue}
                setValue={setTextValue}
                placeholder={`Enter ${LinkTypes[selectedLink]}`}
              />
              <button
                disabled={linkValue.length === 0 || loading}
                onClick={addLink}
                className={`${
                  linkValue.length === 0 || loading
                    ? "bg-gray-500"
                    : "bg-blue-500"
                } mt-12 w-full px-4 py-2 text-white rounded-md`}
              >
                Add
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddLinks;
