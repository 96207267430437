import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./views/Landing";
import Profile from "./views/Profile";
import Setup from "./views/Setup";
import Loading from "./views/Loading";

function App() {
  return (
    <Router>
      <div className="w-[100vw] h-[100vh] max-w-[100vw] max-h-[100vh] bg-[#141414] overflow-hidden flex justify-center items-center">
        <div id="grain" />

        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/user/:id" element={<Loading />} />
          <Route path="*" element={<Landing />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
