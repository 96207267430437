import React from 'react';

function Progress({ completed }) {
  // Ensure completed is a number and constrain between 0 and 100
  const percentage = Math.min(100, Math.max(0, completed));

  return (
    <div className="w-full bg-gray-400 shadow-lg border-b-2 border-zinc-700 absolute top-0 z-50 h-4 dark:bg-gray-700">
      <div
        className="bg-blue-800 transition-all ease-out duration-1000 h-4"
        style={{ width: `${percentage}%` }}
      >
        <div
          className="transition-all ease-out duration-1000 h-full shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
        />
      </div>
    </div>
  );
}

export default Progress;
