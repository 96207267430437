import React from "react";

function TextArea({ title, required, value, setValue, placeholder, subtitle, name }) {
  return (
    <div className="flex mt-6 max-w-[500px] w-full flex-col items-center justify-start">
      <div className="flex gap-1 items-start w-full justify-start">
        <p className="font-medium text-white">{title}</p>
        {required && <p className=" font-bold text-blue-500">*</p>}
      </div>

      {subtitle && (
        <div className="w-full flex items-center gap-2 mt-1 mb-1">
          <div className="rounded-sm w-[2px] bg-blue-500 h-full" />
          <p className="text-xs font-light text-zinc-300">{subtitle}</p>
        </div>
      )}

      <textarea
        name={name || title}
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        className="w-full mt-2 shadow-lg h-32 rounded-md border bg-zinc-800 border-zinc-400 p-2 focus:border-zinc-200 text-white resize-none"
      />
    </div>
  );
}

export default TextArea;
