import React, { useEffect, useState } from "react";
import "../index.css";

// Assuming SVGs have been converted to React components
import { ReactComponent as Vector1 } from "../assets/Vector1.svg";
import { ReactComponent as Vector2 } from "../assets/Vector2.svg";
import { ReactComponent as Vector3 } from "../assets/Vector3.svg";
import { ReactComponent as Vector4 } from "../assets/Vector4.svg";
import { ReactComponent as Vector5 } from "../assets/Vector5.svg";
import { ReactComponent as Vector6 } from "../assets/Vector6.svg";

const vectors = [Vector1, Vector2, Vector3, Vector4, Vector5, Vector6];

const MovingVectors = () => {
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    // Initialize positions and directions
    let vectorData = vectors.map(() => ({
      pos: {
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
      },
      dir: {
        // Increase speed factor for quicker movement
        x: (Math.random() - 0.5) * 4, // Increased speed
        y: (Math.random() - 0.5) * 4,
      },
    }));

    const updatePositionsSmoothly = () => {
      const newVectorData = vectorData.map(({ pos, dir }) => {
        let newX = pos.x + dir.x;
        let newY = pos.y + dir.y;

        // Check and reverse direction if hitting the viewport edge
        if (newX <= 0 || newX >= window.innerWidth) {
          dir.x *= -1;
          newX += dir.x;
        }
        if (newY <= 0 || newY >= window.innerHeight) {
          dir.y *= -1;
          newY += dir.y;
        }

        return { pos: { x: newX, y: newY }, dir };
      });

      setPositions(newVectorData.map(({ pos }) => pos));
      vectorData = newVectorData;
    };

    // Reduce interval for quicker and smoother updates
    const intervalId = setInterval(updatePositionsSmoothly, 5); // More frequent updates

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
    <div className="w-screen h-screen overflow-hidden max-h-[100vh] max-w-[100vw] absolute top-0 left-0">
      {vectors.map((Vector, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            zIndex: index + 10,
            transition: "transform 0.1s linear", // Quicker transitions
            transform: `translate(${positions[index]?.x}px, ${positions[index]?.y}px) scale(2)`,
          }}
        >
          <Vector />
        </div>
      ))}
    </div>
    <div className="grain"/>
    </>
  );
};

export default MovingVectors;
