import React from "react";
import Input from "../../components/Input";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Touchbase from "../../assets/Touchbase.svg";

function Professional({ prevStep, nextStep, formData, handleFormData }) {
  const handleChange = (key, value) => {
    handleFormData("professional", key, value);
  };

  const isFormValid = () => {
    return "Cameron" === "Cameron";
  };

  return (
    <div className="w-screen overflow-hidden  relative h-screen z-[150] p-8 pt-0 pb-0 flex flex-col items-center justify-center">
      <h2 className="text-3xl w-full text-center font-bold text-white">
        Professional Info
      </h2>
      <h6 className="text-md font-light w-full text-center text-zinc-300 mb-8 mt-1">
        Optional but recommended
      </h6>
      <Input
        title="Role"
        subtitle={"Your official title, minus the buzzwords"}
        type="text"
        value={formData.role}
        setValue={(value) => handleChange("role", value)}
        placeholder="Your role"
      />

      <Input
        title="Company / Organization"
        subtitle={"The company that keeps you up at night (in a good way, hopefully)"}
        type="text"
        value={formData.company}
        setValue={(value) => handleChange("company", value)}
        placeholder="Your company or organization"
      />

      <Input
        title="University"
        type="text"
        subtitle={"University name: You can skip the GPA part, we're all friends here."}
        value={formData.university}
        setValue={(value) => handleChange("university", value)}
        placeholder="Your university"
      />
      <div className="w-full gap-2 flex items-center justify-center mt-24">
        <button
          onClick={prevStep}
          className="cursor-pointer text-zinc-300 bg-zinc-700 hover:bg-zinc-600 border border-zinc-600 rounded-lg p-2 px-4 duration-200 hover:scale-105 active:scale-95 transition"
        >
          Previous
        </button>
        <button
          onClick={isFormValid() ? nextStep : undefined}
          disabled={!isFormValid()}
          className={`${
            isFormValid()
              ? "cursor-pointer opacity-100 text-white"
              : "cursor-not-allowed opacity-75 text-zinc-500"
          } ${
            isFormValid() ? "bg-blue-600 hover:bg-blue-500" : "bg-zinc-800"
          } border ${
            isFormValid() ? "border-blue-800" : "border-zinc-900"
          } rounded-lg p-2 px-6 duration-200 ${
            isFormValid() ? "hover:scale-105 active:scale-95 shadow-lg" : ""
          } transition`}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Professional;
