import React, { useState } from "react";

function FileInput({ id, title, required, setValue, subtitle }) {
  const [filename, setFilename] = useState("");

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file type
      if (!file.type.match("image/jpeg") && !file.type.match("image/png")) {
        alert("Please select a JPG or PNG file.");
        setFilename("");
        setValue({ file: null, previewUrl: "" }); // Reset file input
        return; // Stop further execution
      }

      // Check file size (25MB)
      if (file.size > 25 * 1024 * 1024) {
        alert("File size should not exceed 25MB.");
        setFilename("");
        setValue({ file: null, previewUrl: "" }); // Reset file input
        return; // Stop further execution
      }


      const fileUrl = URL.createObjectURL(file);
      setValue({ file, previewUrl: fileUrl });
      setFilename(file.name);
    }
  };

  return (
    <div className="flex mt-6 w-full flex-col items-center justify-start">
      <div className="flex gap-1 items-start w-full justify-start">
        <p className="font-medium text-white">{title}</p>
        {required && <p className="font-bold text-blue-500">*</p>}
      </div>
      {subtitle && (
        <div className="w-full flex items-center gap-2 mt-1 mb-4">
          <div className="rounded-sm w-[2px] bg-blue-500 h-full" />
          <p className="text-xs font-light text-zinc-300">{subtitle}</p>
        </div>
      )}
      <div className="w-full mt-2">
        <input
          type="file"
          id={id}
          onChange={handleChange}
          className="opacity-0 absolute"
          style={{ position: "absolute", zIndex: "-1" }}
        />
        <label
          htmlFor={id} // Reference the unique id here
          className="cursor-pointer w-full flex items-center justify-center px-4 py-2 bg-zinc-800 border border-zinc-400 rounded-md shadow-lg text-white hover:bg-zinc-700 focus:outline-none focus:border-zinc-200 transition duration-200"
        >
          {filename || "Choose File"}
        </label>
        {filename && (
          <div className="text-sm text-zinc-300 mt-2 text-center truncate">
            {filename}
          </div>
        )}
      </div>
    </div>
  );
}

export default FileInput;
