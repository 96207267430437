import React, { useRef, useState } from "react";
import Input from "./Input";
import CloseIcon from "@mui/icons-material/Close";
import emailjs from "@emailjs/browser";
import TextArea from "./TextArea";

function Connect({ setConnectModal, userName, userEmail }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();

  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    setLoading(true);

    emailjs
      .sendForm("service_rexl3az", "template_4rj0w1i", form.current, {
        publicKey: "zJXaXVBC4wdBNXnHT",
      })
      .then(
        (response) => {
          alert("Your contact info has been sent successfully!");
          setConnectModal(false);
          setLoading(false);
        },
        (error) => {
          alert("Failed to send the message, please try again.");
          setLoading(false);
        }
      );

    setLoading(false);
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="w-screen h-screen bg-black/90 absolute flex items-center justify-center">
      <form
        ref={form}
        onSubmit={sendEmail}
        className="p-8 w-[90%] relative z-20 py-16 rounded-md border-2 border-[#333333] drop-shadow-xl bg-[#121212]"
      >
        <h1 className="text-white text-2xl font-semibold w-full text-center">
          Connect With Me
        </h1>
        <div className="flex flex-col mt-6 max-w-[500px] w-full items-center justify-start">
          <label className="font-medium text-white flex gap-1 items-start w-full justify-start">
            Name <span className="font-bold text-blue-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            required
            placeholder="Your name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            className="w-full mt-2 shadow-lg h-10 rounded-md border bg-zinc-800 border-zinc-400 p-2 focus:border-zinc-200 text-white"
          />
        </div>

        <div className="flex flex-col mt-6 max-w-[500px] w-full items-center justify-start">
          <label className="font-medium text-white flex gap-1 items-start w-full justify-start">
            Email <span className="font-bold text-blue-500">*</span>
          </label>
          <input
            type="email"
            name="email"
            required
            placeholder="Your email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className="w-full mt-2 shadow-lg h-10 rounded-md border bg-zinc-800 border-zinc-400 p-2 focus:border-zinc-200 text-white"
          />
        </div>

        <TextArea
          title="Message"
          required
          value={message}
          setValue={setMessage}
          placeholder="Your message"
          name="message"
        />
        {/* Add hidden inputs here */}
        <input type="hidden" name="recipient_name" value={userName} />
        <input type="hidden" name="to_email" value={userEmail} />
        <input
          type="hidden"
          name="date"
          value={new Date().toLocaleDateString()}
        />

        <button
          type="submit"
          disabled={loading}
          className="mt-10 w-full h-10 rounded-md bg-blue-500 text-white hover:bg-blue-600"
        >
          Send Message
        </button>
        <button
          type="button"
          onClick={() => setConnectModal(false)}
          className="absolute top-2 right-2 text-white"
        >
          <CloseIcon />
        </button>
      </form>
    </div>
  );
}

export default Connect;
