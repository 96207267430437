import React, { useEffect, useState } from "react";
import Spinny from "../components/Spinny";
import MovingVectors from "../components/MovingVectors";
import StartScreen from "./Setup/StartScreen";
import Personal from "./Setup/Personal";
import Professional from "./Setup/Professional";
import Progress from "../components/Progress";
import Social from "./Setup/Social";
import Images from "./Setup/Images";
import Final from "./Setup/Final";
import axios from "axios";

function Setup({ id }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.troylabs.vc/setup/${id}`);
        setisActive(response.data.active);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setisActive(true);
      }
    };

    if (id) fetchData();
  }, [id]);

  const [formData, setFormData] = useState({
    personal: {
      name: "",
      email: "",
      pin: "",
    },
    professional: {
      role: "",
      company: "",
      university: "",
    },
    social: {
      linkedin: "",
      github: "",
      x: "",
    },
    images: {
      profileImage: {
        file: null,
        previewUrl: "",
      },
      companyImage: {
        file: null,
        previewUrl:
          "https://media.licdn.com/dms/image/D4D0BAQEshD2Z4CC_qg/company-logo_100_100/0/1704835620575/troy_labs_logo?e=1717632000&v=beta&t=lTEZhBCIKC-pq18SOnaP89NYajMYle3alGhy-vDSk0M",
      },
      backgroundImage: {
        file: null,
        previewUrl:
          "https://images.unsplash.com/photo-1674255499627-640d84485ecc?q=80&w=2816&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
    },
  });

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    if (currentStep === 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const handleFormData = (section, key, value) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [key]: value,
      },
    });
  };

  if (isActive) {
    return (
      <div className="w-screen h-screen overflow-hidden flex flex-col items-center justify-center relative max-h-[100vh] max-w-[100vw]">
        <h1 className="text-white text-2xl mt-6 text-center font-semibold">Error:</h1>
        <h1 className="text-white text-2xl mt-6 text-center font-semibold">Account already created</h1>
      </div>
    );
  } else {
    const renderStep = () => {
      switch (currentStep) {
        case 0:
          return (
            <Personal
              nextStep={nextStep}
              formData={formData.personal}
              handleFormData={handleFormData}
            />
          );
        case 1:
          return (
            <Professional
              prevStep={previousStep}
              nextStep={nextStep}
              formData={formData.professional}
              handleFormData={handleFormData}
            />
          );
        case 2:
          return (
            <Social
              prevStep={previousStep}
              nextStep={nextStep}
              formData={formData.social}
              handleFormData={handleFormData}
            />
          );
        case 3:
          return (
            <Images
              prevStep={previousStep}
              nextStep={nextStep}
              formData={formData.images}
              setFormData={setFormData}
            />
          );
        case 4:
          return <Final prevStep={previousStep} formData={formData} id={id} />;
        default:
          return <StartScreen />;
      }
    };

    return (
      <div className="w-screen h-screen overflow-hidden relative max-h-[100vh] max-w-[100vw]">
        {renderStep()}
        <Progress completed={currentStep * 25} />
        <MovingVectors />
      </div>
    );
  }
}

export default Setup;
