import React from "react";
import Spinny from "../../components/Spinny";

function StartScreen() {
  return (
    <div className="w-screen relative h-screen z-20 p-8 flex flex-col items-center justify-center">
      <div className="flex justify-center items-center">
        <Spinny />
      </div>
      <h1 className="text-white text-5xl mt-6 font-semibold">TouchBase</h1>
      <p className="text-zinc-400 text-sm mt-2">Developed by TroyLabs</p>
    </div>
  );
}

export default StartScreen;
